import React from 'react';

function Footer(props) {
  let footerData = props.data;
  let countryData = props.country;
  return (
    <>
      {
        footerData ? (
          footerData.footer ? (
            <footer className="footer" role="navigation" aria-label="footer navigation">
              <div className="container footer-container">
                <div className="footer-menus">
                  <div className="footer-logo">
                    <a href="/">
                      {
                          <img src="./../assets/images/NBCUniversal.png" alt="NBCUniversal logo" />
                      }
                    </a>
                    <h6 className="safety-txt">NBCUniversal Production Safety</h6>
                  </div>
                  <div className="footer-submenus">
                    {/* footer primary menu items */}
                    <div className="footer-menu-list">
                      <ul className="submenu-list">
                        {footerData.footer.footerMenu.map((footer, index) => {
                          return (
                            <li key={index} className={"container-li " + (footer.options.containerclass  ? footer.options.containerclass : '') + " "+ (countryData.countrycode == 'us'? countryData.countrycode : 'non-us')}>
                              <a href={footer.link} target={footer.options.target === '_blank' ? '_blank' : '_self'} className={"footer-menu-link " + (footer.options.class ? footer.options.class[0] : '')} rel="noreferrer" >
                                {footer.title}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {/* footer secondary menu items */}
                    <div className="footer-menu-list">
                      <ul className="submenu-list">
                        {footerData.footer.footerSecondaryMenu.map((footerSecondary, index2) => {
                          return (
                            <li key={index2}>
                              <a href={footerSecondary.link} style={{ backgroundImage: 'url(' + footerSecondary.icon + ')' }} target={footerSecondary.options.target === '_blank' ? '_blank' : '_self'} className={"footer-menu-link " + (footerSecondary.options.class ? footerSecondary.options.class[0] : '')} rel="noreferrer" >
                                {footerSecondary.title}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>

                </div>
                <div className="copyright-txt">© 2023 NBCUNIVERSAL MEDIA, LLC.</div>
              </div>
            </footer>
          ) : (
            null
          )
        ) : (
          null
        )
      }
    </>
  )
}

export default Footer;